import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { OrderStatusContext } from "./OrderStatusContext";
import IOrderInfo from "../portal/order-processing/interfaces/IOrderInfo";
import { PubSub, Auth, Hub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { ZenObservable } from "zen-observable-ts";
import IOrderShipmentInfo from "../portal/order-processing/interfaces/IOrderShipmentInfo";
import { CloneOrderEvent, GenerateOrderEventFromShipment, ParseOrderEvent, ParseShipmentEvent } from "../helpers/EventHelper";
import { GetPortalConfigurationFromEnvironmentFile } from "../helpers/ConfigurationHelper";

type Props = {
  children: React.ReactNode;
};

export enum DemoEventMode {
  NONE = "None",
  STORE_MODE = "Store Mode",
  ALL_EVENTS = "All Events",
}

export const OrderStatusContextProvider = ({ children }: Props) => {
  const [createdOrders, setCreatedOrders] = useState<IOrderInfo[]>([]);
  const [processedOrders, setProcessedOrders] = useState<IOrderInfo[]>([]);
  const [fulfilledOrders, setFulfilledOrders] = useState<IOrderInfo[]>([]);
  const [preparedShipmentGroups, setPreparedShipmentGroups] = useState<IOrderInfo[]>([]);
  const [processedShipmentGroups, setProcessedShipmentGroups] = useState<IOrderInfo[]>([]);
  const [orderEventNotification, setOrderEventNotification] = useState<IOrderInfo>();
  const [shipmentEventNotification, setShipmentEventNotification] = useState<IOrderShipmentInfo>();
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [featureFlagAllEvents, setFeatureFlagAllEvents] = useState<boolean>(false);
  const [demoEventMode, setDemoEventMode] = useState<DemoEventMode>(DemoEventMode.NONE);
  const connections = useRef<Map<DemoEventMode, ZenObservable.Subscription>>(new Map<DemoEventMode, ZenObservable.Subscription>());

  const httpClient = axios.create();
  const portalConfiguration = GetPortalConfigurationFromEnvironmentFile();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  useEffect(() => {
    async function initializeContext() {
      try {
        // console.info("Store Context Initialized");
        console.log("Context initializing");

        Hub.remove("auth", listener);
        Hub.listen("auth", listener);

        await applyUserName();

        await loadUserProfile();
      } catch (error) {
        console.error(error);
      }
    }

    initializeContext();

    return () => Hub.remove("auth", listener);
  }, []);

  const applyUserName = async () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.currentUserInfo().then(async (info) => {
          setUserId(info.attributes.sub);
          setUserName(info.attributes.given_name + " " + info.attributes.family_name);
        });
      })
      .catch((err) => {
        setUserId("");
        setUserName("");
      });
  };

  const loadUserProfile = async () => {
    const user = await Auth.currentAuthenticatedUser();

    const response = await httpClient.get(`/users`, {
      params: {
        userId: user.attributes.sub,
      },
    });
    console.log(response.data);
    setStoreId(response.data.storeId ?? "");
    setDemoEventMode((response.data.demoEventMode as DemoEventMode) ?? DemoEventMode.NONE);
  };

  const addCreatedOrders = async (order: IOrderInfo) => {
    try {
      setCreatedOrders([order].concat(createdOrders));
    } catch (error) {
      console.error(error);
    }
  };

  const removeCreatedOrders = async (order: IOrderInfo) => {
    var items = createdOrders.filter((item) => item.orderId !== order.orderId);
    setCreatedOrders(items);
  };

  const addProcessedOrders = async (order: IOrderInfo) => {
    try {
      setProcessedOrders([order].concat(processedOrders));
    } catch (error) {
      console.error(error);
    }
  };

  const removeProcessedOrders = async (order: IOrderInfo) => {
    var items = processedOrders.filter((item) => item.orderId !== order.orderId);
    setProcessedOrders(items);
  };

  const addPreparedShipments = async (shipment: IOrderShipmentInfo) => {
    try {
      var preparedShipmentGroup = preparedShipmentGroups.find((p) => p.orderId === shipment.orderId);
      if (preparedShipmentGroup) {
        //console.log("Existing group");
        var existingShipment = preparedShipmentGroup.shipments.find((element) => {
          return element.shipmentId === shipment.shipmentId;
        });
        if (existingShipment) {
          console.log("Shipment already exists");
          return;
        }

        preparedShipmentGroup.shipments = [shipment]
          .concat(preparedShipmentGroup.shipments)
          .sort(function (a: IOrderShipmentInfo, b: IOrderShipmentInfo) {
            if (a.shipmentCreationDate < b.shipmentCreationDate) return 1;
            if (a.shipmentCreationDate > b.shipmentCreationDate) return -1;
            return 0;
          });
        await refreshPreparedShipments(preparedShipmentGroup);
      } else {
        //console.log("New group");
        var processedOrder = processedOrders.find((element) => {
          return element.orderId === shipment.orderId;
        });
        if (processedOrder) {
          preparedShipmentGroup = CloneOrderEvent(processedOrder, "ShipmentPrepared");
          preparedShipmentGroup.shipments = [shipment].concat(preparedShipmentGroup.shipments);
          setPreparedShipmentGroups([preparedShipmentGroup].concat(preparedShipmentGroups));
          //console.log(preparedShipmentGroup);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refreshPreparedShipments = async (preparedShipmentGroup: IOrderInfo) => {
    try {
      var index = preparedShipmentGroups.indexOf(preparedShipmentGroup);
      var orderId = preparedShipmentGroup.orderId;
      var updatedList = preparedShipmentGroups.filter((item) => item.orderId !== orderId);
      updatedList.splice(index, 0, preparedShipmentGroup);
      setPreparedShipmentGroups(updatedList);
    } catch (error) {
      console.error(error);
    }
  };

  const removePreparedShipments = async (shipment: IOrderShipmentInfo) => {
    var preparedShipmentGroup = preparedShipmentGroups.find((element) => {
      return element.orderId === shipment.orderId;
    });
    if (preparedShipmentGroup) {
      preparedShipmentGroup.shipments = preparedShipmentGroup.shipments.filter((item) => item.shipmentId !== shipment.shipmentId);
      // console.log(preparedShipmentGroup.shipments);
      if (preparedShipmentGroup.shipments.length > 0) {
        // console.log("removePreparedShipments-Many shipments");
        await refreshPreparedShipments(preparedShipmentGroup);
      } else {
        // console.log("removePreparedShipments-One shipment");
        var items = preparedShipmentGroups.filter((item) => item.orderId !== shipment.orderId);
        setPreparedShipmentGroups(items);
      }
    }
  };

  const addProcessedShipments = async (shipment: IOrderShipmentInfo) => {
    try {
      var processedShipmentGroup = processedShipmentGroups.find((element) => {
        return element.orderId === shipment.orderId;
      });
      if (processedShipmentGroup) {
        // console.log("addProcessedShipments-Existing group");
        var existingShipment = processedShipmentGroup.shipments.find((element) => {
          return element.shipmentId === shipment.shipmentId;
        });
        if (existingShipment) {
          // console.log("Shipment already exists");
          return;
        }
        processedShipmentGroup.shipments = [shipment].concat(processedShipmentGroup.shipments);
        await refreshProcessedShipments(processedShipmentGroup);
      } else {
        // console.log("addProcessedShipments-New group");
        var preparedShipmentGroup = preparedShipmentGroups.find((element) => {
          return element.orderId === shipment.orderId;
        });

        if (preparedShipmentGroup) {
          processedShipmentGroup = CloneOrderEvent(preparedShipmentGroup, "ShipmentProcessed");
          processedShipmentGroup.shipments = [shipment].concat(processedShipmentGroup.shipments);
          setProcessedShipmentGroups([processedShipmentGroup].concat(processedShipmentGroups));
        } else {
          processedShipmentGroup = GenerateOrderEventFromShipment(shipment, "ShipmentProcessed");
          setProcessedShipmentGroups([processedShipmentGroup].concat(processedShipmentGroups));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refreshProcessedShipments = async (processedShipmentGroup: IOrderInfo) => {
    try {
      var index = processedShipmentGroups.indexOf(processedShipmentGroup);
      var orderId = processedShipmentGroup.orderId;
      var updatedList = processedShipmentGroups.filter((item) => item.orderId !== orderId);
      updatedList.splice(index, 0, processedShipmentGroup);
      setProcessedShipmentGroups(updatedList);
    } catch (error) {
      console.error(error);
    }
  };

  const clearPreparedShipments = async (order: IOrderInfo) => {
    var items = preparedShipmentGroups.filter((item) => item.orderId !== order.orderId);
    setPreparedShipmentGroups(items);
  };

  const clearProcessedShipments = async (order: IOrderInfo) => {
    var items = processedShipmentGroups.filter((item) => item.orderId !== order.orderId);
    setProcessedShipmentGroups(items);
  };

  const addFulfilledOrders = async (order: IOrderInfo) => {
    try {
      setFulfilledOrders([order].concat(fulfilledOrders));
    } catch (error) {
      console.error(error);
    }
  };

  const processOrderEvents = async (eventNotification: IOrderInfo) => {
    switch (eventNotification.eventType) {
      case "OrderCreated":
        addCreatedOrders(eventNotification);
        return;
      case "OrderProcessed":
        removeCreatedOrders(eventNotification);
        addProcessedOrders(eventNotification);
        return;
      case "OrderFulfilled":
        removeProcessedOrders(eventNotification);
        clearPreparedShipments(eventNotification);
        clearProcessedShipments(eventNotification);
        addFulfilledOrders(eventNotification);
        return;
    }
  };

  const processShipmentEvents = async (eventNotification: IOrderShipmentInfo) => {
    switch (eventNotification.eventType) {
      case "ShipmentPrepared":
        addPreparedShipments(eventNotification);
        return;
      case "ShipmentProcessed":
        removePreparedShipments(eventNotification);
        addProcessedShipments(eventNotification);
        return;
    }
  };

  useEffect(() => {
    if (orderEventNotification) {
      processOrderEvents(orderEventNotification);
    }
    return;
  }, [orderEventNotification]);

  useEffect(() => {
    if (shipmentEventNotification) {
      processShipmentEvents(shipmentEventNotification);
    }
    return;
  }, [shipmentEventNotification]);

  // Start subscriptions

  useEffect(() => {
    if (userId === "") return;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //establishConnection(0);
        establishWebSocketConnectionForUser();
      })
      .catch((err) => {
        //console.error(err);
      });
  }, [userId]);

  useEffect(() => {
    const existingConnection = connections.current.get(DemoEventMode.STORE_MODE);
    if (existingConnection) {
      if (!existingConnection.closed) {
        console.log("Unsubscribing from existing " + DemoEventMode.STORE_MODE + " connection");
        existingConnection.unsubscribe();
      }
    }
    establishWebSocketConnectionForUser();
  }, [storeId]);

  const establishWebSocketConnectionForUser = () => {
    if (demoEventMode === DemoEventMode.NONE) {
      clearConnections();
      return;
    }

    if (userId === "") return;

    var connection = connections.current.get(demoEventMode);

    if (!connection || connection.closed) {
      console.log(demoEventMode + " activated");
      establishWebSocketConnection(0);
    } else {
      console.log("Connection for Event Mode '" + demoEventMode + "' has already established.");
    }
  };

  const getTopicName = () => {
    switch (demoEventMode) {
      case DemoEventMode.STORE_MODE:
        return "order-status/store/" + storeId;

      case DemoEventMode.ALL_EVENTS:
        return "order-status/all-admin-events";

      default:
        throw new Error(demoEventMode + " is not supported");
    }
  };

  useEffect(() => {
    establishWebSocketConnectionForUser();
  }, [demoEventMode]);

  async function establishWebSocketConnection(delay: number) {
    await timeout(delay);
    subscribeToIotTopic();
  }

  const subscribeToIotTopic = () => {
    clearConnections();

    const topicName = getTopicName();
    console.info("Connecting to IoT Core for topic " + topicName);

    const iotProvider = new AWSIoTProvider({
      aws_pubsub_region: portalConfiguration.PubSubRegion,
      aws_pubsub_endpoint: portalConfiguration.PubSubEndpoint,
    });

    PubSub.addPluggable(iotProvider);

    const newConnection = PubSub.subscribe(topicName).subscribe({
      next: (data) => onOrderEvent(data),
      error: (error) => onIotError(error),
      complete: () => console.log("Done"),
    });

    connections.current.set(demoEventMode, newConnection);

    console.info("Connected to IoT Core for topic " + topicName);
  };

  const clearConnections = () => {
    // Unsubscribe from all other WebSockets connections
    Object.values<string>(DemoEventMode)
      .filter((item) => item !== DemoEventMode.NONE)
      .forEach(function (mode) {
        const existingConnection = connections.current.get(mode as DemoEventMode);
        if (existingConnection) {
          //console.log("Existing " + mode + " connection closed?: " + existingConnection.closed);
          if (!existingConnection.closed) {
            console.log("Unsubscribing from existing " + mode + " connection");
            existingConnection.unsubscribe();
            //console.log("Existing " + mode + " connection closed?: " + existingConnection.closed);
          }
        }
      });

    PubSub.removePluggable("AWSIoTProvider");
  };

  const onIotError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }
    //PubSub.removePluggable("AWSIoTProvider");
    establishWebSocketConnection(5000);
  };

  const listener = (data: any) => {
    console.info(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        applyUserName();
        loadUserProfile();
        break;

      case "signOut":
        applyUserName();
        clearConnections();
        setDemoEventMode(DemoEventMode.NONE);
        break;
    }
  };

  // End Subscriptions

  const onOrderEvent = (data: string) => {
    console.info(data);
    const messageData = JSON.parse(JSON.stringify(data));

    const eventType: string = messageData.value.eventType;
    const niceMessage = getNiceMessage(eventType);

    if (eventType.startsWith("Order")) {
      var orderEventInfo: IOrderInfo = ParseOrderEvent(messageData);

      orderEventInfo["message"] = niceMessage;

      setOrderEventNotification(orderEventInfo);
    } else {
      var shipmentEventInfo: IOrderShipmentInfo = ParseShipmentEvent(messageData);
      shipmentEventInfo["message"] = niceMessage;
      setShipmentEventNotification(shipmentEventInfo);
    }
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const getNiceMessage = (eventType: string) => {
    switch (eventType) {
      case "OrderCreated":
        return "We received your order, it is being processed.";
      case "OrderProcessed":
        return "We processed your order and will ship it to you soon.";
      case "OrderFulfilled":
        return "Your order has been fulfilled, we processed all order shipments.";
      case "ShipmentPrepared":
        return "We packing a part of your order and will ship it to you soon.";
      case "ShipmentProcessed":
        return "We shipped a part of your order, you can track it using provided tracking number.";
      default:
        return "Generic Notification";
    }
  };

  return (
    <OrderStatusContext.Provider
      value={{
        processOrderEvents,
        processShipmentEvents,
        createdOrders,
        processedOrders,
        preparedShipmentGroups,
        processedShipmentGroups,
        fulfilledOrders,
        userId,
        userName,
        setUserName,
        featureFlagAllEvents,
        setFeatureFlagAllEvents,
        demoEventMode,
        setDemoEventMode,
        storeId,
        setStoreId,
        clearConnections,
        establishWebSocketConnectionForUser,
      }}
    >
      {children}
    </OrderStatusContext.Provider>
  );
};
