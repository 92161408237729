declare const process: {
  env: {
    REACT_APP_PORTAL_TITLE: string;
    REACT_APP_PORTAL_API_URL: string;
    REACT_APP_PUB_SUB_REGION: string;
    REACT_APP_PUB_SUB_ENDPOINT: string;
    REACT_APP_STORE_PORTAL_URL: string;
    REACT_APP_COGNITO_REGION: string;
    REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: string;
    REACT_APP_AWS_USER_POOLS_ID: string;
    REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID: string;
    REACT_APP_AWS_COGNITO_USERNAME_ATTRIBUTES: string;
    REACT_APP_AWS_COGNITO_SIGNUP_ATTRIBUTES: string;
    REACT_APP_AWS_COGNITO_MFA_CONFIGURATION: string;
    REACT_APP_AWS_COGNITO_MFA_TYPES: string;
    REACT_APP_AWS_COGNITO_PASSWORD_POLICY_MIN_LENGTH: number;
    REACT_APP_AWS_COGNITO_PASSWORD_POLICY_CHARACTERS: string;
    REACT_APP_AWS_COGNITO_VERIFICATION_MECHANISMS: string;
  };
};

export const GetAmplifyConfigurationFromEnvironmentFile = () => {
  const amplifyConfiguration: IAmplifyConfiguration = {
    aws_project_region: process.env.REACT_APP_COGNITO_REGION,
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    aws_cognito_username_attributes: JSON.parse(process.env.REACT_APP_AWS_COGNITO_USERNAME_ATTRIBUTES),
    aws_cognito_signup_attributes: JSON.parse(process.env.REACT_APP_AWS_COGNITO_SIGNUP_ATTRIBUTES),
    aws_cognito_mfa_configuration: process.env.REACT_APP_AWS_COGNITO_MFA_CONFIGURATION,
    aws_cognito_mfa_types: JSON.parse(process.env.REACT_APP_AWS_COGNITO_MFA_TYPES),
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: process.env.REACT_APP_AWS_COGNITO_PASSWORD_POLICY_MIN_LENGTH,
      passwordPolicyCharacters: JSON.parse(process.env.REACT_APP_AWS_COGNITO_PASSWORD_POLICY_CHARACTERS),
    },
    aws_cognito_verification_mechanisms: JSON.parse(process.env.REACT_APP_AWS_COGNITO_VERIFICATION_MECHANISMS!),
  };
  return amplifyConfiguration;
};

export const GetPortalConfigurationFromEnvironmentFile = () => {
  const portalConfiguration: IPortalConfiguration = {
    PortalTitle: process.env.REACT_APP_PORTAL_TITLE,
    ApiUrl: process.env.REACT_APP_PORTAL_API_URL,
    PubSubRegion: process.env.REACT_APP_PUB_SUB_REGION,
    PubSubEndpoint: process.env.REACT_APP_PUB_SUB_ENDPOINT,
    StorePortalUrl: process.env.REACT_APP_STORE_PORTAL_URL,
  };
  return portalConfiguration;
};

export interface IPasswordSettings {
  passwordPolicyMinLength: number;
  passwordPolicyCharacters: string[];
}

export interface IAmplifyConfiguration {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_cognito_identity_pool_id: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_cognito_username_attributes: string[];
  aws_cognito_signup_attributes: string[];
  aws_cognito_mfa_configuration: string;
  aws_cognito_mfa_types: string[];
  aws_cognito_password_protection_settings: IPasswordSettings;
  aws_cognito_verification_mechanisms: string[];
}

export interface IPortalConfiguration {
  PortalTitle: string;
  ApiUrl: string;
  PubSubRegion: string;
  PubSubEndpoint: string;
  StorePortalUrl: string;
}
