import { motion } from "framer-motion";
import uniqolor from "uniqolor";
import IPreparedShipmentCard from "../interfaces/IPreparedShipmentCard";
import { GetOrderEventAnimation, GetShipmentEventAnimation } from "../../../helpers/AnimationHelper";
import { AnimatePresence } from "framer-motion";
import "./PreparedShipmentCard.css";

export default function PreparedShipmentCard({ orderDetails }: IPreparedShipmentCard): JSX.Element {
  return (
    <motion.div
      className="prepared-shipment-event-pane"
      layout="position"
      key={orderDetails.orderId}
      variants={GetOrderEventAnimation()}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div
        className="prepared-shipment-event-container"
        style={{
          backgroundColor: uniqolor(orderDetails.orderId, {
            saturation: [35, 70],
            lightness: [75, 90],
            differencePoint: 50,
          }).color,
        }}
      >
        <div className="prepared-shipment-event-vertical-segment">
          <span className="prepared-shipment-event-attribute-label">Order Id:</span>
          <span className="prepared-shipment-event-id-label">{orderDetails.orderId.substring(0, 18)}</span>
        </div>
        <div className="prepared-shipment-event-horizontal-delimiter" />
        <div className="prepared-shipment-event-vertical-segment">
          <span className="prepared-shipment-event-attribute-label">Event Date:</span>
          <span className="prepared-shipment-event-date-label">
            {new Intl.DateTimeFormat("us-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(orderDetails.eventDate))}
          </span>
        </div>
        <div className="prepared-shipment-event-horizontal-delimiter" />
        <div className="prepared-shipment-event-card-segment-list">
          <AnimatePresence>
            {orderDetails.shipments.map((shipment) => {
              return (
                <motion.div
                  layout
                  key={shipment.shipmentId}
                  variants={GetShipmentEventAnimation()}
                  className="prepared-shipment-event-card-segment"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  style={{
                    backgroundColor: uniqolor(shipment.shipmentId, {
                      saturation: [35, 70],
                      lightness: [75, 90],
                      differencePoint: 50,
                    }).color,
                  }}
                >
                  <span className="prepared-shipment-event-card-id-label">Shipment Id:</span>
                  <span className="prepared-shipment-event-card-id-value">{shipment.shipmentId.substring(0, 18)}</span>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
}
