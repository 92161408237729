import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import uniqolor from "uniqolor";
import IProcessedOrderCard from "../interfaces/IProcessedOrderCard";
import { GetOrderEventAnimation } from "../../../helpers/AnimationHelper";
import "./ProcessedOrderCard.css";

export default function ProcessedOrderCard({ orderDetails }: IProcessedOrderCard): JSX.Element {
  const [shipmentCount, setShipmentCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    async function calculateValues() {
      var price = 0;
      orderDetails.productInventory.forEach(function (inventory) {
        price = price + inventory.price * inventory.quantity;
      });

      setShipmentCount(orderDetails.shipments.length);
      setTotalPrice(price);
    }

    calculateValues();
  }, []);

  return (
    <motion.div
      className="processed-order-event-pane"
      layout
      key={orderDetails.orderId}
      variants={GetOrderEventAnimation()}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div
        className="processed-order-event-container"
        style={{
          backgroundColor: uniqolor(orderDetails.orderId, {
            saturation: [35, 70],
            lightness: [75, 90],
            differencePoint: 50,
          }).color,
        }}
      >
        <div className="processed-order-event-vertical-segment">
          <span className="processed-order-event-attribute-label">Order Id:</span>
          <span className="processed-order-event-id-label">{orderDetails.orderId.substring(0, 18)}</span>
        </div>
        <div className="processed-order-event-horizontal-delimiter" />
        <div className="processed-order-event-vertical-segment">
          <span className="processed-order-event-attribute-label">Event Date:</span>
          <span className="processed-order-event-date-label">
            {new Intl.DateTimeFormat("us-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(orderDetails.eventDate))}
          </span>
        </div>
        <div className="processed-order-event-horizontal-delimiter" />
        <div className="processed-order-event-horizontal-segment">
          <span className="processed-order-event-bottom-attribute-label processed-order-event-padding-bottom">Shipments:</span>
          <span className="processed-order-event-bottom-attribute-value">{shipmentCount}</span>
          <div className="processed-order-event-vertical-delimiter" />
          <span className="processed-order-event-bottom-attribute-value">
            {new Intl.NumberFormat("us-US", {
              style: "currency",
              currency: "USD",
            }).format(totalPrice)}
          </span>
        </div>
      </div>
    </motion.div>
  );
}
