import IOrderEventBucket from "../interfaces/IOrderEventBucket";
import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OrderEventBucket.css";
import NewOrderCard from "./NewOrderCard";
import IOrderInfo from "../interfaces/IOrderInfo";
import ProcessedOrderCard from "./ProcessedOrderCard";
import FulfilledOrderCard from "./FulfilledOrderCard";
import PreparedShipmentCard from "./PreparedShipmentCard";
import ProcessedShipmentCard from "./ProcessedShipmentCard";

export default function OrderEventBucket({ bucketTitle, bucketIcon, orderEvents }: IOrderEventBucket): JSX.Element {
  const getOrderCard = (orderDetails: IOrderInfo) => {
    switch (orderDetails.eventType) {
      case "OrderCreated":
        return <NewOrderCard key={orderDetails.orderId} orderDetails={orderDetails} />;
      case "OrderProcessed":
        return <ProcessedOrderCard key={orderDetails.orderId} orderDetails={orderDetails} />;
      case "OrderFulfilled":
        return <FulfilledOrderCard key={orderDetails.orderId} orderDetails={orderDetails} />;
      case "ShipmentPrepared":
        return <PreparedShipmentCard key={orderDetails.orderId} orderDetails={orderDetails} />;
      case "ShipmentProcessed":
        return <ProcessedShipmentCard key={orderDetails.orderId} orderDetails={orderDetails} />;
    }
  };

  return (
    <div className="event-bucket-segment">
      <div className="event-bucket-title-container">
        <FontAwesomeIcon className="event-bucket-title-icon" icon={bucketIcon} />
        <span className="event-bucket-title-label">{bucketTitle}</span>
      </div>
      <AnimatePresence>
        {orderEvents.map((item) => {
          return getOrderCard(item);
        })}
      </AnimatePresence>
    </div>
  );
}
