import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { OrderStatusContextProvider } from "./contexts/OrderStatusContextProvider";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <OrderStatusContextProvider>
        <App />
      </OrderStatusContextProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
