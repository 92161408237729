import axios from "axios";
import { useState, useEffect } from "react";
import IProfileManager from "./interfaces/IProfileManager";
import Table from "react-bootstrap/Table";
import { Auth } from "aws-amplify";
import "./ProfileManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import IUserInfo from "./interfaces/IUserInfo";
import { useOrderStatusContext } from "../../contexts/OrderStatusContext";
import { CloseButton, Spinner } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { DemoEventMode } from "../../contexts/OrderStatusContextProvider";

export default function ProfileManager(props: IProfileManager): JSX.Element {
  const orderStatusContext = useOrderStatusContext()!;
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [applyingStoreIdInProgress, setApplyingStoreIdInProgress] = useState(false);
  const [signOutInProgress, setSignOutInProgress] = useState(false);
  const [localStoreId, setLocalStoreId] = useState<string>("");

  const httpClient = axios.create();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  useEffect(() => {
    async function getUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserInfo({
          userId: user.attributes.sub,
          email: user.attributes.email,
          emailVerified: user.attributes.email_verified,
          firstName: user.attributes.given_name,
          lastName: user.attributes.family_name,
        });
      } catch (error) {
        console.error(error);
      }
    }

    getUserInfo();
    // console.log(userInfo);
    //setEventMode("two");
  }, []);

  const getVerificationIcon = (verificationStatus: Boolean | undefined) => {
    if (verificationStatus) {
      return faSquareCheck;
    } else {
      return faBan;
    }
  };

  const onSignOut = async () => {
    try {
      setSignOutInProgress(true);
      orderStatusContext.clearConnections();
      await Auth.signOut();
      setSignOutInProgress(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSignOutInProgress(false);
    }
  };

  const onEventModeChange = async (event: any) => {
    console.log("Dropdown triggered");
    const payload = {
      userId: orderStatusContext.userId,
      storeId: orderStatusContext.storeId,
      eventMode: event.value,
    };
    const response = await httpClient.post(`/users`, payload);
    console.log(response);
    orderStatusContext.setDemoEventMode(event.value);
  };

  const onInputChange = async (value: string) => {
    setLocalStoreId(value);
  };

  const onSetStoreId = async () => {
    try {
      setApplyingStoreIdInProgress(true);

      var demoEventMode = DemoEventMode.STORE_MODE;

      if (localStoreId !== "") {
        orderStatusContext.setStoreId(localStoreId);
        // if (orderStatusContext.demoEventMode === DemoEventMode.STORE_MODE) {
        //   console.error("Reseting Connections");
        //   orderStatusContext.setDemoEventMode(DemoEventMode.NONE);
        // }
        // orderStatusContext.setDemoEventMode(DemoEventMode.STORE_MODE);
      }

      const payload = {
        userId: orderStatusContext.userId,
        storeId: localStoreId,
        demoEventMode: demoEventMode,
      };

      const response = await httpClient.post(`/users`, payload);
      console.log(response);

      setLocalStoreId("");

      setApplyingStoreIdInProgress(false);
    } catch (error) {
      console.error(error);
    } finally {
      setApplyingStoreIdInProgress(false);
    }
  };

  const onClearStoreId = async () => {
    var eventMode = DemoEventMode.STORE_MODE;
    orderStatusContext.setStoreId(orderStatusContext.userId);

    const payload = {
      userId: orderStatusContext.userId,
      storeId: orderStatusContext.userId,
      eventMode: eventMode,
    };

    const response = await httpClient.post(`/users`, payload);
    console.log(response);

    setLocalStoreId(orderStatusContext.userId);
  };

  return (
    <div className="profile-container">
      <section className="profile-section">
        <span className="profile-label">Your Profile:</span>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td className="profile-table-bold">First Name</td>
              <td>{userInfo?.firstName}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Last Name</td>
              <td>{userInfo?.lastName}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Email</td>
              <td>{userInfo?.email}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Email Verified</td>
              <td className="profile-table-column-center">
                <FontAwesomeIcon icon={getVerificationIcon(userInfo?.emailVerified)} color="orange" />
              </td>
            </tr>
            <tr>
              <td className="profile-table-bold">User Store information</td>
              <td className="profile-table-column-center">
                <div className="store-id-component">
                  {orderStatusContext.storeId !== "" ? (
                    <div className="store-id-display-component">
                      <span>StoreId:</span>
                      <span className="store-id-display-store-span">{orderStatusContext.storeId}</span>
                      <CloseButton className="store-id-clear-button" onClick={onClearStoreId} />
                    </div>
                  ) : (
                    <div className="store-id-display-store-empty-component">
                      <span className="store-id-display-store-empty-span">StoreId is not set</span>
                    </div>
                  )}

                  <div className="store-id-input-component">
                    <input
                      className="store-id-input"
                      placeholder="Please set desired StoreId..."
                      value={localStoreId}
                      onChange={(e) => onInputChange(e.target.value)}
                    />
                  </div>
                  <button
                    className="store-id-button"
                    type="submit"
                    disabled={applyingStoreIdInProgress || orderStatusContext.storeId === localStoreId}
                    onClick={onSetStoreId}
                  >
                    Set StoreID
                    {applyingStoreIdInProgress ? (
                      <Spinner className="store-id-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      <span />
                    )}
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td className="profile-table-bold">Event Processing Mode</td>
              <td>
                <Dropdown
                  className="profile-event-dropdown"
                  options={Object.values<string>(DemoEventMode).filter((item) => item !== DemoEventMode.NONE && item !== DemoEventMode.ALL_EVENTS)}
                  onChange={onEventModeChange}
                  value={orderStatusContext.demoEventMode !== DemoEventMode.NONE ? orderStatusContext.demoEventMode.toString() : ""}
                  placeholder="Select mode"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="sign-out-component">
          <button className="sign-out-button" type="submit" disabled={signOutInProgress} onClick={onSignOut}>
            Sign Out
            {signOutInProgress ? (
              <Spinner className="sign-out-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span />
            )}
          </button>
        </div>
      </section>
    </div>
  );
}
