import IInventoryInfo from "../portal/order-processing/interfaces/IInventoryInfo";
import IOrderInfo from "../portal/order-processing/interfaces/IOrderInfo";
import IOrderShipmentInfo from "../portal/order-processing/interfaces/IOrderShipmentInfo";
import IOrderProductInfo from "../portal/order-processing/interfaces/IOrderProductInfo";

export const CloneOrderEvent = (order: IOrderInfo, eventType: string) => {
  var orderEventInfo: IOrderInfo = {
    eventId: order.eventId,
    orderId: order.orderId,
    eventDate: order.eventDate,
    eventType: order.eventType,
    message: order.message,
    products: [],
    shipments: [],
    productInventory: [],
  };

  if (eventType) {
    orderEventInfo.eventType = eventType;
  }

  return orderEventInfo;
};

export const GenerateOrderEventFromShipment = (shipment: IOrderShipmentInfo, eventType: string) => {
  var orderEventInfo: IOrderInfo = {
    eventId: shipment.eventId,
    orderId: shipment.orderId,
    eventDate: shipment.eventDate,
    eventType: shipment.eventType,
    message: shipment.message,
    products: [],
    shipments: [],
    productInventory: [],
  };

  orderEventInfo.shipments.push(shipment);

  if (eventType) {
    orderEventInfo.eventType = eventType;
  }

  return orderEventInfo;
};

export const ParseOrderEvent = (messageData: any) => {
  var orderEventInfo: IOrderInfo = {
    eventId: messageData.value.eventId,
    orderId: messageData.value.orderId,
    eventDate: messageData.value.eventDate,
    eventType: messageData.value.eventType,
    message: "",
    products: ParseOrderProducts(messageData),
    shipments: ParseOrderShipments(messageData),
    productInventory: ParseOrderInventory(messageData),
  };

  return orderEventInfo;
};

const ParseOrderProducts = (messageData: any) => {
  var products: IOrderProductInfo[] = [];
  if (messageData.value.products) {
    var messageProducts: [] = messageData.value.products;
    messageProducts.forEach(function (product: any) {
      var productInfo: IOrderProductInfo = {
        productId: product.productId,
        quantity: product.quantity,
        price: product.price,
      };

      products.push(productInfo);
    });
  }

  return products;
};

const ParseOrderShipments = (messageData: any) => {
  var shipments: IOrderShipmentInfo[] = [];
  if (messageData.value.shipments) {
    var messageShipments: [] = messageData.value.shipments;
    messageShipments.forEach(function (shipment: any) {
      var shipmentInfo: IOrderShipmentInfo = {
        eventId: shipment.eventId,
        eventDate: shipment.eventDate,
        eventType: shipment.eventType,
        message: shipment.message,
        shipmentId: shipment.shipmentId,
        orderId: shipment.orderId,
        trackingNumber: shipment.trackingNumber,
        products: ParseOrderProducts(messageData),
        shipmentCreationDate: "",
        shipmentStatus: "",
      };

      shipments.push(shipmentInfo);
    });
  }

  return shipments;
};

const ParseOrderInventory = (messageData: any) => {
  var orderInventory: IInventoryInfo[] = [];
  if (messageData.value.productInventory) {
    var messageInventory: [] = messageData.value.productInventory;
    messageInventory.forEach(function (inventory: any) {
      var inventoryInfo: IInventoryInfo = {
        productId: inventory.productId,
        warehouseId: inventory.warehouseId,
        quantity: inventory.quantity,
        price: inventory.price,
      };

      orderInventory.push(inventoryInfo);
    });
  }

  return orderInventory;
};

export const ParseShipmentEvent = (messageData: any) => {
  var shipmentEventInfo: IOrderShipmentInfo = {
    eventId: messageData.value.eventId,
    shipmentId: messageData.value.shipmentId,
    trackingNumber: messageData.value.trackingNumber,
    orderId: messageData.value.orderId,
    eventDate: messageData.value.eventDate,
    eventType: messageData.value.eventType,
    shipmentCreationDate: messageData.value.shipmentCreationDate,
    shipmentStatus: messageData.value.shipmentStatus,
    message: "",
    products: ParseOrderProducts(messageData),
  };

  return shipmentEventInfo;
};
