import IOrderInfo from "../portal/order-processing/interfaces/IOrderInfo";
import IOrderShipmentInfo from "../portal/order-processing/interfaces/IOrderShipmentInfo";
import { v4 as uuidv4 } from "uuid";

export const GenerateOrderCreatedEvent = async (orderId: string) => {
  var createdOrderEvent: IOrderInfo = {
    eventId: "b66f8f13-244b-d5d3-f80a-e32ce675e85c",
    orderId: orderId,
    eventDate: "2022-05-17T00:57:42Z",
    eventType: "OrderCreated",
    message: "We received your order, it is being processed.",
    products: [
      {
        productId: "4caa6ee1-d08a-49be-87cc-f962b8c2f11d",
        quantity: 7,
        price: 0,
      },
      {
        productId: "244b0a17-f3d1-4729-a2e7-29144741c45e",
        quantity: 6,
        price: 0,
      },
      {
        productId: "14a3a0de-d0d9-42f5-852e-e8d31429bd12",
        quantity: 8,
        price: 0,
      },
      {
        productId: "3b8a2feb-f7f2-498f-b433-05aa9e5c0e74",
        quantity: 4,
        price: 0,
      },
      {
        productId: "10d18d37-7d5f-4d03-9d03-e0503f6a1633",
        quantity: 4,
        price: 0,
      },
    ],
    shipments: [],
    productInventory: [],
  };

  return createdOrderEvent;
};

export const GenerateOrderProcessedEvent = async (orderId: string, delay: number) => {
  await timeout(delay);

  var processedOrderEvent: IOrderInfo = {
    eventId: "2d6bbb81-f7eb-6eee-30e4-a5ac1d2f1f10",
    orderId: orderId,
    eventDate: "2022-05-17T00:57:51Z",
    eventType: "OrderProcessed",
    message: "We processed your order and will ship it to you soon.",
    products: [],
    shipments: [
      {
        shipmentId: uuidv4(),
        orderId: orderId,
        eventId: "",
        eventDate: "",
        eventType: "",
        message: "",
        trackingNumber: "",
        shipmentCreationDate: "",
        shipmentStatus: "",
        products: [],
      },
      {
        shipmentId: uuidv4(),
        orderId: orderId,
        eventId: "",
        eventDate: "",
        eventType: "",
        message: "",
        trackingNumber: "",
        shipmentCreationDate: "",
        shipmentStatus: "",
        products: [],
      },
      {
        shipmentId: uuidv4(),
        orderId: orderId,
        eventId: "",
        eventDate: "",
        eventType: "",
        message: "",
        trackingNumber: "",
        shipmentCreationDate: "",
        shipmentStatus: "",
        products: [],
      },
    ],
    productInventory: [
      {
        productId: "31eddc32-6ee1-4068-941f-bf775f45b4b4",
        warehouseId: "428e3d91-b921-452b-ad39-a66859fc4552",
        price: 1008.21,
        quantity: 5,
      },
      {
        productId: "33990b9a-56e7-407f-8926-dfac4900eec3",
        warehouseId: "6d4b86ef-a2a6-4536-b23a-2581cfcc1931",
        price: 998.01,
        quantity: 5,
      },
      {
        productId: "244b0a17-f3d1-4729-a2e7-29144741c45e",
        warehouseId: "dd42195f-302b-43f0-a30e-95a49faf7208",
        price: 197.03,
        quantity: 4,
      },
      {
        productId: "14a3a0de-d0d9-42f5-852e-e8d31429bd12",
        warehouseId: "6d4b86ef-a2a6-4536-b23a-2581cfcc1931",
        price: 739.07,
        quantity: 3,
      },
      {
        productId: "331b0931-9046-4237-a542-639fc731885e",
        warehouseId: "6d4b86ef-a2a6-4536-b23a-2581cfcc1931",
        price: 907.31,
        quantity: 4,
      },
    ],
  };

  return processedOrderEvent;
};

export const GenerateOrderFulfilledEvent = async (orderId: string, delay: number) => {
  await timeout(delay);

  var fulfilledOrderEvent: IOrderInfo = {
    eventId: "50a2832f-2b9a-16e7-29d7-bd0597c2e2a9",
    orderId: orderId,
    eventDate: "2022-05-17T00:58:06Z",
    eventType: "OrderFulfilled",
    message: "All ordered products have been shipped.",
    products: [],
    shipments: [],
    productInventory: [],
  };

  return fulfilledOrderEvent;
};

export const GenerateShipmentPreparedEvent = async (shipment: IOrderShipmentInfo, delay: number) => {
  await timeout(delay);
  var preparedShipmentEvent: IOrderShipmentInfo = {
    eventId: "10107f77-ec0b-a76e-dc40-c3b2c257de86",
    orderId: shipment.orderId,
    eventDate: "2022-05-21T21:21:18Z",
    eventType: "ShipmentPrepared",
    message: "We packing a part of your order and will ship it to you soon.",
    products: [
      {
        productId: "a523fc85-8bcb-40a8-9d3b-4d1c126c8468",
        quantity: 8,
        price: 0,
      },
    ],
    shipmentCreationDate: "2022-05-21T21:21:22Z",
    shipmentStatus: "PREPARED",
    shipmentId: shipment.shipmentId,
    trackingNumber: "",
  };

  return preparedShipmentEvent;
};

export const GenerateShipmentProcessedEvent = async (shipment: IOrderShipmentInfo, delay: number) => {
  await timeout(delay);

  var preparedShipmentEvent: IOrderShipmentInfo = {
    eventId: "9db487f9-ce34-d65b-e98a-63cd81b287c9",
    orderId: shipment.orderId,
    eventDate: "2022-05-21T21:21:22Z",
    eventType: "ShipmentProcessed",
    message: "We shipped a part of your order. Please use provided tracking number to monitor shipment status.",
    products: [
      {
        productId: "a523fc85-8bcb-40a8-9d3b-4d1c126c8468",
        quantity: 8,
        price: 0,
      },
    ],
    shipmentCreationDate: "2022-05-21T21:21:22Z",
    shipmentStatus: "PROCESSED",
    shipmentId: shipment.shipmentId,
    trackingNumber: "d9f743d2-2bee-4da6-b9aa-858c1b862bfb",
  };

  return preparedShipmentEvent;
};

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}
