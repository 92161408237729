import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import uniqolor from "uniqolor";
import INewOrderCard from "../interfaces/INewOrderCard";
import { GetOrderEventAnimation } from "../../../helpers/AnimationHelper";
import "./NewOrderCard.css";

export default function NewOrderCard({ orderDetails }: INewOrderCard): JSX.Element {
  const [uniqueProductQuantity, setUniqueProductQuantity] = useState(0);
  const [productQuantity, setProductQuantity] = useState(0);

  useEffect(() => {
    async function calculateValues() {
      var totalQuantity = 0;
      orderDetails.products.forEach(function (product) {
        totalQuantity = totalQuantity + product.quantity;
      });

      setUniqueProductQuantity(orderDetails.products.length);
      setProductQuantity(totalQuantity);
    }

    calculateValues();
  }, []);

  return (
    <motion.div
      className="new-order-event-pane"
      layout
      key={orderDetails.orderId}
      variants={GetOrderEventAnimation()}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div
        className="new-order-event-container"
        style={{
          backgroundColor: uniqolor(orderDetails.orderId, {
            saturation: [35, 70],
            lightness: [75, 90],
            differencePoint: 50,
          }).color,
        }}
      >
        <div className="new-order-event-vertical-segment">
          <span className="new-order-event-attribute-label">Order Id:</span>
          <span className="new-order-event-id-label">{orderDetails.orderId.substring(0, 18)}</span>
        </div>
        <div className="new-order-event-horizontal-delimiter" />
        <div className="new-order-event-vertical-segment">
          <span className="new-order-event-attribute-label">Event Date:</span>
          <span className="new-order-event-date-label">
            {new Intl.DateTimeFormat("us-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(orderDetails.eventDate))}
          </span>
        </div>
        <div className="new-order-event-horizontal-delimiter" />
        <div className="new-order-event-horizontal-segment">
          <span className="new-order-event-bottom-attribute-label">Products:</span>
          <span className="new-order-event-bottom-attribute-value">{productQuantity}</span>
          <div className="new-order-event-vertical-delimiter" />
          <span className="new-order-event-bottom-attribute-label new-order-event-padding-bottom">Unique:</span>
          <span className="new-order-event-bottom-attribute-value">{uniqueProductQuantity}</span>
        </div>
      </div>
    </motion.div>
  );
}
