import { useOrderStatusContext } from "../../contexts/OrderStatusContext";
import IOrderProcessingManager from "./interfaces/IOrderProcessingManager";
import { faTruckFast, faCartFlatbed, faTruckLoading, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import {
  GenerateOrderCreatedEvent,
  GenerateOrderFulfilledEvent,
  GenerateOrderProcessedEvent,
  GenerateShipmentPreparedEvent,
  GenerateShipmentProcessedEvent,
} from "../../helpers/EventGenerator";
import OrderEventBucket from "./components/OrderEventBucket";
import "./OrderProcessingManager.css";
import IOrderShipmentInfo from "./interfaces/IOrderShipmentInfo";

export default function OrderProcessingManager(props: IOrderProcessingManager): JSX.Element {
  const orderStatusContext = useOrderStatusContext()!;

  const onPublishOrderCreatedEventHandler = async (event: React.ChangeEvent<any>) => {
    var orderId = uuidv4();
    var createdEvent = await GenerateOrderCreatedEvent(orderId);
    await orderStatusContext.processOrderEvents(createdEvent);
  };

  const onPublishOrderProcessedEventsHandler = async (event: React.ChangeEvent<any>) => {
    if (orderStatusContext.createdOrders.length > 0) {
      const processedEvent = await GenerateOrderProcessedEvent(orderStatusContext.createdOrders[0].orderId, 0);
      await orderStatusContext.processOrderEvents(processedEvent);
    }
  };

  const onPublishShipmentPreparedEventsHandler = async (event: React.ChangeEvent<any>) => {
    var processedOrderShipments: IOrderShipmentInfo[] = [];
    orderStatusContext.processedOrders.forEach(function (order) {
      order.shipments.forEach(function (shipment) {
        processedOrderShipments.push(shipment);
      });
    });
    //console.log(processedOrderShipments);

    var preparedShipments: IOrderShipmentInfo[] = [];
    orderStatusContext.preparedShipmentGroups.forEach(function (order) {
      order.shipments.forEach(function (shipment) {
        preparedShipments.push(shipment);
      });
    });
    //console.log(preparedShipments);

    var unprocessedItems = processedOrderShipments.filter((itemA) => {
      return !preparedShipments.find((itemB) => {
        return itemA.shipmentId === itemB.shipmentId;
      });
    });
    //console.log(unprocessedItems);

    if (unprocessedItems.length > 0) {
      const shipmentPreparedEvent = await GenerateShipmentPreparedEvent(unprocessedItems[0], 0);
      //console.log(shipmentPreparedEvent);
      await orderStatusContext.processShipmentEvents(shipmentPreparedEvent);
    }
  };

  const onPublishShipmentProcessedEventsHandler = async (event: React.ChangeEvent<any>) => {
    var preparedShipments: IOrderShipmentInfo[] = [];
    orderStatusContext.preparedShipmentGroups.forEach(function (order) {
      order.shipments.forEach(function (shipment) {
        preparedShipments.push(shipment);
      });
    });
    //console.log(preparedShipments);
    var processedShipments: IOrderShipmentInfo[] = [];
    orderStatusContext.processedShipmentGroups.forEach(function (order) {
      order.shipments.forEach(function (shipment) {
        processedShipments.push(shipment);
      });
    });
    //console.log(processedShipments);
    var unprocessedItems = preparedShipments.filter((itemA) => {
      return !processedShipments.find((itemB) => {
        return itemA.shipmentId === itemB.shipmentId;
      });
    });
    //console.log(unprocessedItems);

    if (unprocessedItems.length > 0) {
      const shipmentProcessedEvent = await GenerateShipmentProcessedEvent(unprocessedItems[0], 0);
      console.log(shipmentProcessedEvent);
      await orderStatusContext.processShipmentEvents(shipmentProcessedEvent);
    }
  };

  const onPublishOrderFulfilledEventsHandler = async (event: React.ChangeEvent<any>) => {
    if (orderStatusContext.processedOrders.length > 0) {
      const fulfilledEvent = await GenerateOrderFulfilledEvent(orderStatusContext.processedOrders[0].orderId, 0);
      await orderStatusContext.processOrderEvents(fulfilledEvent);
    }
  };

  const onPublishEventsHandler = async (event: React.ChangeEvent<any>) => {
    const orderId = uuidv4();
    orderStatusContext.processOrderEvents(await GenerateOrderCreatedEvent(orderId));
    orderStatusContext.processOrderEvents(await GenerateOrderProcessedEvent(orderId, 1000));
    orderStatusContext.processOrderEvents(await GenerateOrderFulfilledEvent(orderId, 2000));
  };

  return (
    <div className="orders-dashboard-container">
      {orderStatusContext.createdOrders.length > 0 ||
      orderStatusContext.processedOrders.length > 0 ||
      orderStatusContext.fulfilledOrders.length > 0 ||
      orderStatusContext.preparedShipmentGroups.length > 0 ||
      orderStatusContext.processedShipmentGroups.length > 0 ? (
        <div className="orders-dashboard-temp">
          <div className="orders-dashboard-buttons-section">
            <button onClick={onPublishEventsHandler}>All events</button>
            <button onClick={onPublishOrderCreatedEventHandler}>Order Created Event</button>
            <button onClick={onPublishOrderProcessedEventsHandler}>Order Processed Event</button>
            <button onClick={onPublishShipmentPreparedEventsHandler}>Shipment Prepared Event</button>
            <button onClick={onPublishShipmentProcessedEventsHandler}>Shipment Processed Event</button>
            <button onClick={onPublishOrderFulfilledEventsHandler}>Order Fulfilled Event</button>
          </div>
          <section className="orders-dashboard-section">
            <OrderEventBucket bucketTitle="New Orders:" bucketIcon={faCartShopping} orderEvents={orderStatusContext.createdOrders} />
            <OrderEventBucket bucketTitle="Orders in Progress:" bucketIcon={faCartFlatbed} orderEvents={orderStatusContext.processedOrders} />
            <OrderEventBucket bucketTitle="Shipments Prepared:" bucketIcon={faCartFlatbed} orderEvents={orderStatusContext.preparedShipmentGroups} />
            <OrderEventBucket
              bucketTitle="Shipments Processed:"
              bucketIcon={faTruckLoading}
              orderEvents={orderStatusContext.processedShipmentGroups}
            />
            <OrderEventBucket bucketTitle="Fulfilled Orders:" bucketIcon={faTruckFast} orderEvents={orderStatusContext.fulfilledOrders} />
          </section>
        </div>
      ) : (
        <div className="orders-dashboard-container-empty">
          <section className="orders-dashboard-empty-section">
            <span className="orders-dashboard-empty-label">You do not have any Orders in flight.</span>
          </section>
        </div>
      )}
    </div>
  );
}
