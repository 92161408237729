import "./EventManager.css";
import IEventManager from "./interfaces/IEventManager";
import { QRCodeSVG } from "qrcode.react";
import { useOrderStatusContext } from "../../contexts/OrderStatusContext";

export default function EventManager({ storePortalUrl }: IEventManager): JSX.Element {
  const orderStatusContext = useOrderStatusContext()!;
  return (
    <div className="event-container">
      <div className="event-section">
        <div className="event-label">Please scan the barcode and submit an order:</div>
        <QRCodeSVG className="event-barcode" value={storePortalUrl + "/?sessionId=" + orderStatusContext.storeId} />
      </div>
      <div className="event-copyright">
        ©{" "}
        {new Intl.DateTimeFormat("us-US", {
          year: "numeric",
        }).format(Date.now())}
        , Amazon Web Services, Inc. or its affiliates. All rights reserved.
      </div>
    </div>
  );
}
//https://www.store.staging.appmodlive.com/?storeId=123
